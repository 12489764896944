document.addEventListener("DOMContentLoaded", () => {
  const clickButton = document.getElementsByClassName("flash-messages__close-btn")[0];
  if(clickButton === undefined)
    return null;

  clickButton.addEventListener("click", (event) => {
    const flashMessages = document.getElementsByClassName("flash-messages")[0];
    flashMessages.style.display = "none";
  });
});
