import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["btnClose", "textInput", "form"];

  connect() {
    this.displayBtnClose();
  }

  displayBtnClose() {
    if (this.textInput.value.length > 0)
      this.btnClose.style.display = 'block';
    else
      this.btnClose.style.display = 'none';
  }

  clearTextInput() {
    this.textInput.value=''
    this.form.submit();
  }

  get btnClose() {
    return this.btnCloseTarget;
  }

  get textInput() {
    return this.textInputTarget;
  }

}
