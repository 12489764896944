import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["select"];

  goToCollectionByCountry() {
    let option = this.select.selectedIndex
    let countryId = this.select.item(option).dataset.id
    window.location.href = `/archive_items?q[country_ids_list_cont]=${countryId}`;
  }

  get select() {
    return this.selectTarget;
  }
}
