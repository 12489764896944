import { Controller } from 'stimulus'

export default class extends Controller {
  static targets = ['form', 'organizationsSelect', "btnClose", "textInput"]

  connect() {
    this.displayBtnClose();
  }

  displayBtnClose() {
    if (this.textInput.value.length > 0)
      this.btnClose.style.display = 'block';
    else
      this.btnClose.style.display = 'none';
  }

  clearTextInput() {
    this.textInput.value=''
    this.form.submit();
  }

  clearOrganizations() {
    this.organizationsSelect.value = null;
  }

  submit() {
    this.form.submit()
  }

  get form() {
    return this.formTarget
  }

  get organizationsSelect() {
    return this.organizationsSelectTarget;
  }

  get btnClose() {
    return this.btnCloseTarget;
  }

  get textInput() {
    return this.textInputTarget;
  }

}
